<template>
   <div class="page_section">
      <MobileTitle :title="__t('search')"/>
      <Banner :content="banner"/>

      <div class="container">
         <div class="search-results">
            <div class="results-count mb15 text-center">
               <div>Ihre Suche nach "<strong>{{ query }}</strong>"</div>
            </div>
            <template v-if="search.result.pages.length">
               <div class="a-search has-media" :key="index" v-for="(page, index) in search.result.pages">
                  <div class="inner cursor-pointer" @click="searchResultClicked(page)">
                     <h4 class="title">{{ page.title }}</h4>
                     <div class="info mb0" v-if="page.description">
                        <p class="mb0" v-html="page.description"></p>
                     </div>
                  </div>
               </div>
            </template>

            <template v-if="search.result.lines.length">
               <div class="a-search has-media" :key="index" v-for="(line, index) in search.result.lines">
                  <div class="inner cursor-pointer" @click="searchResultClicked(line)">
                     <h4 class="title">{{ line.title }}</h4>
                  </div>
               </div>
            </template>
         </div>
      </div>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import MobileTitle from "../_common/MobileTitle";
   import Footer from "../_common/Footer";
   import Banner from "../_content_elements/Banner";

   export default {
      name: "Search",
      components: {
         Banner,
         Footer,
         MobileTitle
      },
      data() {
         return {
            banner: {
               title: this.__t('search_results'),
               image: '/abellio_banner.jpg',
               smallBanner: true
            },
            query: null
         }
      },
      computed: {
         ...mapState([
            'page',
            'search',
            'locales',
            'settings'
         ]),
      },
      mounted() {
         this.query = this.$route.query.q;
         if (this.query === undefined) {
            this.$router.push({name: 'home'})
         } else {
            this.$store.commit('loading/setFullPageLoaderVisible', true);
            this.$store.dispatch('search/searchAll', this.query).then(() => {
               this.$store.commit('loading/setFullPageLoaderVisible', false);
            });
         }
      },
      methods: {
         searchResultClicked: function (item) {
            if (item.slugs[this.locales.currentLocale] !== null) {
               this.$router.push({path: item.slugs[this.locales.currentLocale]});
            } else {
               this.$router.push({path: item.slugs[this.locales.defaultLocale]});
            }
         }
      },
   }
</script>
